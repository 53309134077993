import { userAxios } from '@/services'

const tutorialCategoryList = async (perPage) => {
    try {
        return await userAxios.get(`tutorial-categories/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryView = async (id) => {
    try {
        return await userAxios.get(`tutorial-categories/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryCreate = async (data) => {
    try {
        return await userAxios.post('tutorial-categories', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`tutorial-categories/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`tutorial-categories/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`tutorial-categories/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`tutorial-categories/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCategoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`tutorial-categories/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    tutorialCategoryList,
    tutorialCategoryView,
    tutorialCategoryCreate,
    tutorialCategoryUpdate,
    tutorialCategoryDelete,
    tutorialCategoryPagination,
    tutorialCategoryFilter,
    tutorialCategoryFilterPagination
}