import { userAxios } from '@/services'

const tutorialList = async (perPage) => {
    try {
        return await userAxios.get(`tutorial-items/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialView = async (id) => {
    try {
        return await userAxios.get(`tutorial-items/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialCreate = async (data) => {
    try {
        return await userAxios.post('tutorial-items', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialUpdate = async (id, data) => {
    try {
        return await userAxios.put(`tutorial-items/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialDelete = async (id) => {
    try {
        return await userAxios.delete(`tutorial-items/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`tutorial-items/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`tutorial-items/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`tutorial-items/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    tutorialList,
    tutorialView,
    tutorialCreate,
    tutorialUpdate,
    tutorialDelete,
    tutorialPagination,
    tutorialFilter,
    tutorialFilterPagination
}