<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col>
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group :label="$t('Form.Name')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="stateData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ailment"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Categories"
              rules="required"
            >
              <b-form-group label="Categories" label-for="status">
                <v-select
                  id="status"
                  v-model="stateData.category_id"
                  :options="optionsCategories"
                  :reduce="(val) => val.id"
                  :state="getValidationState(validationContext)"
                  :clearable="false"
                  label="name"
                  :class="{
                    'is-invalid':
                      validationContext.touched && !validationContext.valid,
                  }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Status"
              rules="required"
            >
              <b-form-group :label="$t('Form.Status')" label-for="status">
                <v-select
                  id="status"
                  v-model="stateData.status"
                  :options="optionsStatus"
                  :state="getValidationState(validationContext)"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  label="text"
                  :class="{
                    'is-invalid':
                      validationContext.touched && !validationContext.valid,
                  }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Body"
              rules="required"
            >
              <div id="toolbar">
                <button type="button" class="ql-bold"></button>
                <button type="button" class="ql-italic"></button>
                <button type="button" class="ql-underline"></button>
                <button type="button" class="ql-strike"></button>
                <button type="button" class="ql-blockquote"></button>
                <button type="button" class="ql-code-block"></button>
                <button type="button" class="ql-list" value="ordered"></button>
                <button type="button" class="ql-list" value="bullet"></button>
                <button type="button" class="ql-script" value="sub"></button>
                <button type="button" class="ql-script" value="super"></button>
                <button type="button" class="ql-indent" value="-1"></button>
                <button type="button" class="ql-indent" value="+1"></button>
                <button type="button" class="ql-direction"></button>
                <select class="ql-size">
                  <option value="small"></option>
                  <option value="false"></option>
                  <option value="large"></option>
                  <option value="huge"></option>
                </select>
                <select class="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                  <option value="false"></option>
                </select>
                <select class="ql-font"></select>
                <select class="ql-color"></select>
                <select class="ql-background"></select>
                <select class="ql-align"></select>
                <button type="button" class="ql-clean"></button>
                <button type="button" class="ql-link"></button>
                <button type="button" class="ql-image"></button>
                <button type="button" class="ql-video" @click="handleVideo"></button>
                <button
                  type="button"
                  class="ql-list"
                  value="ordered"
                  @click="insertOrderedList"
                ></button>
                <button
                  type="button"
                  class="ql-custom-button"
                  @click="$refs.fileInput.click()"
                >
                  <feather-icon icon="UploadCloudIcon" />
                </button>
                <b-button
                  v-if="uploadedFiles"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                  @click="uploadFile"
                  style="width: 88px"
                >
                  Subir PDF
                </b-button>
              </div>
              <quill-editor
                ref="myQuillEditor"
                :options="editorOption"
                v-model="stateData.conf"
                :state="getValidationState(validationContext)"
                :class="{
                  'is-invalid':
                    validationContext.touched && !validationContext.valid,
                }"
              >
              </quill-editor>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <input
              @change="handleFileInputChange"
              type="file"
              ref="fileInput"
              class="d-none"
            />
            <embed
              v-if="uploadedFiles"
              :src="uploadedFiles"
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </b-col>
        </b-row>
        <b-row class="flex-row-reverse">
          <b-col cols="auto">
            <b-button
              class="mt-2"
              variant="primary"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import {
  BCard,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import { notificationCheck } from "@/mixins/NotificationSetup";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";

import axiosCV from "@/services/admin/tutorial/tutorials";
import axiosCT from "@/services/admin/tutorial/tutorialCategories";
import axiosEC from "@/services/external-settings/images";

export default {
  components: {
    quillEditor,
    BCard,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    ToastNotification,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      stateData: {
        title: "",
        conf: ``,
        status: null,
        category_id: null,
      },
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
          },
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
        theme: "snow",
      },
      optionsStatus: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      optionsCategories: [],
      fileInput: null, // Almacenará la referencia al input de carga de archivos
      uploadedFiles: null,
      uploadedFileData: null,
      loading: false,
    };
  },
  mounted() {
    this.getCategories();
    this.getEdit();
  },
  methods: {
    handleFileInputChange(event) {
      const file = event.target.files[0];
      this.uploadedFileData = file;
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadedFiles = reader.result;
        };
      }
      // Restablece el valor del input de carga de archivos
      this.fileInput = null;
    },
    handleVideo() {
      // Pedir al usuario que ingrese la URL del video
      const url = prompt("Por favor, ingresa la URL del video:");

      if (url) {
        // Insertar el video con las etiquetas <video> y aplicar los estilos de tamaño
        const videoTag = `<iframe class="ql-video" src="${url}" width="100%" height="300"></iframe>`
        const quill = this.$refs.myQuillEditor.quill;
        const range = quill.getSelection();
        quill.clipboard.dangerouslyPasteHTML(range.index, videoTag);
      }
    },
    async uploadFile() {
      const file = this.uploadedFileData;
      const formData = new FormData();
      formData.append("type", "TUTORIAL");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      this.insertLink(dir_img);
    },
    insertLink(url) {
      // Insertar el enlace en el editor de Quill
      const quill = this.$refs.myQuillEditor.quill;
      const selection = quill.getSelection();
      const index = selection ? selection.index : 0;

      quill.insertText(index, url, "link", url);
      quill.insertText(index, "\n📄", url, "user");
      quill.setSelection(index + 1);
    },
    insertOrderedList() {
      const range = this.$refs.myQuillEditor.quill.getSelection();
      if (range) {
        this.$refs.myQuillEditor.quill.insertText(range.index, "1. ");
      }
    },
    onSubmit() {
      this.loading = true;
      const data = {
        ...this.stateData,
        conf: this.stateData.conf,
      };
      axiosCV
        .tutorialUpdate(this.$route.params.id, data)
        .then((res) => {
          const { type, message } = notificationCheck(res);
          this.loading = false;
          this.getNotify(type, message);

          this.$router.go(-1);
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getValidationState(item) {
      const { getValidationState } = formValidation(this.stateData);
      return getValidationState(item);
    },
    getCategories() {
      axiosCT.tutorialCategoryList(20).then(({ registro: { data } }) => {
        this.optionsCategories = data;
      });
    },
    getEdit() {
      axiosCV.tutorialView(this.$route.params.id).then(({ registro }) => {
        const data = registro;
        this.stateData = {
          conf: data.conf_item,
          title: data.title,
          status: data.status,
          category_id: data.category_id,
        };
      });
    },
    getNotify(type, message) {
      this.$refs.toast[type](message);
    },
  },
};
</script>

<style></style>
